import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "guides"
    }}>{`Guides`}</h1>
    <p>{`There’s a lot of things you can do with MDX, so we’ve begun collecting guides
for common use cases, tools, and patterns.
It’s intended to become the central knowledge base for MDX, so please feel free
to add to them by `}<a parentName="p" {...{
        "href": "/contributing"
      }}>{`contributing`}</a>{`!`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/syntax-highlighting"
        }}>{`Add syntax highlighting to code blocks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/live-code"
        }}>{`Add a live code block`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/table-of-contents"
        }}>{`Implement a dynamic table of contents`}</a></li>
    </ul>
    <h2 {...{
      "id": "customizing"
    }}>{`Customizing`}</h2>
    <p>{`At it’s core MDX is minimal and unopinionated, so there’s often a need to
customize it.
Here are a few ways to do that:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/writing-a-plugin"
        }}>{`Write a custom remark plugin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/custom-loader"
        }}>{`Building a custom webpack loader`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/guides/wrapper-customization"
        }}>{`Wrapper customization`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      